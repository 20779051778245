import React from 'react'
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link className="navbar-brand" to="#">
          Blog Website
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <Link className="nav-item nav-link " to="/createPost">
              Create a Post
            </Link>
            <Link className="nav-item nav-link" to="/viewAllPost">
              View All Post
            </Link>
            <Link className="nav-item nav-link" to="/viewMyPost">
              View My Post
            </Link>

            <Link className="nav-item nav-link" to="/">
              Logout
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header